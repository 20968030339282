@import "mixins";
@import "colours";

.id_projectsHolder {
	@include box-sizing();
	padding: 16px;
	width: 70%;
	margin: 0 auto;
	border-bottom: 1px solid $sectionBorderColour;

	.id_projectsColumnHolder {
		
		width: 100%;
		display: inline-block;
		vertical-align: top;
		@include box-sizing();

		&.id_projects2ColumnView {
			width: 50%;
		}

		&.id_projects2ColumnViewLeftSide {
			padding-right: 8px;
		}

		&.id_projects2ColumnViewRightSide {
			padding-left: 8px;
		}

		.id_projectHolder {
			margin-bottom: 16px;
			width: 100%;
			min-height: 100px;
			border-radius: 5px;
			background-color: #fff;
			box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

			.id_projectImg {
				width: 100%;
				height: auto;
			}

			.id_projectTextHolder {
				@include box-sizing();
				padding: 16px;
				background-color: #F8F8F8;
				border-top: 1px solid #cdcdcd;
				position: relative;

				h3 {
					margin-top: 8px;
				}
				p {
					margin-top: 8px;
				}

				a {
					position: absolute;
					width: 40px;
					height: 40px;
					right: 10px;
					top: -20px;
					box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
					border-radius: 50%;

					.id_linknIconImg {
						width: 100%;
						height: 100%;
						border-radius: 50%;
						background-color: #fff;
						@include linkBtn-transition();
					}

					&:hover {
						.id_linknIconImg {
							// background-color: #4747FF;
							background-color: #DDD;
							transform: rotate(360deg);
						}
					}
				}
			}
		}
	}
}


/*
 * Responsive behaviour
*/
@media all and (max-width: 800px) {
	
	.id_projectsHolder {
		width: 80%;
	}
}

@media all and (max-width: 550px) {
	
	.id_projectsHolder {
		width: 90%;
	}
}