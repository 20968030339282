@import "mixins";
@import "colours";

.id_awardsHolder {
	@include box-sizing();
	padding: 16px;
	width: 70%;
	margin: 0 auto;
	border-bottom: 1px solid $sectionBorderColour;

	.id_awardHolder {
		margin-bottom: 16px;

		.trophyLogo {
			display: inline-block;
			vertical-align: top;
			margin: 0 16px 0 0;
			width: 55px;
			height: auto;
			border-radius: 50%;
			box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
		}

		.id_awardTextHolder {
			display: inline-block;
			width: calc(100% - 87px);
		}
	}
}

/*
 * Responsive behaviour
*/
@media all and (max-width: 800px) {
	
	.id_awardsHolder {
		width: 80%;
	}
}

@media all and (max-width: 550px) {
	
	.id_awardsHolder {
		width: 90%;
	}
}

@media all and (max-width: 440px) {
	
	.id_awardsHolder {
		
		.id_awardHolder {
			margin-bottom: 8px;

			.trophyLogo {
				margin: 0 8px 0 0;
				width: 40px;
			}

			.id_awardTextHolder {
				width: calc(100% - 57px);
			}
		}
	}
}