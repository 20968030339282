@import "settings";
@import "../../bower_components/foundation/scss/foundation";
@import "mixins";
@import "type";
@import "colours";
@import "topBanner";
@import "intro";
@import "awards";
@import "projects";

body {
	background-color: $bodyBagColour;
	font-size: 18px;
	font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}