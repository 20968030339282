@import "colours";

body {
	font-size: 18px;
	font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}

h2 {
	font-size: 1.11em;
	color: $headingColour;
	margin: 0 0 16px 0; 
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h3 {
	font-size: 1em;
	color: $headingColour;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p {
	font-size: 1em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/*
 * Responsive behaviour
*/
@media all and (max-width: 440px) {
	body {
		font-size: 16px;
	}
}